import React from 'react';

import { useLocales } from '8-shared/lib/i18n';

export const P2PColumns = () => {
  const { t } = useLocales('portfolio');

  const columns = [{ title: t('table.columns.count') }, { title: t('table.columns.sell_price') }];

  return (
    <div className={'hidden desktop:flex w-full justify-end'}>
      <div className={'grid grid-cols-3 w-[50%]'}>
        {columns.map((i, index) => (
          <div key={index} className={'mr-[15px] text-center opacity-50 font-500 text-1.4 uppercase'}>
            {i.title}
          </div>
        ))}
      </div>
    </div>
  );
};
