import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentLanguage } from '7-entities/app';
import { useLocales } from '8-shared/lib/i18n';
import { IActiveP2POffer } from '../../../../../../7-entities/user/model/types';

interface IProps {
  item: IActiveP2POffer;
}

export const P2PRow = ({ item }: IProps) => {
  const { i18n } = useLocales('portfolio');
  const lang = useSelector(selectCurrentLanguage);
  return (
    <div
      className={
        'flex flex-col rounded-[15px] mb-[15px] items-center px-[10px] py-[15px] desktop:px-[20px] border border-white_opacity_13 bg-white_opacity_5 w-full'
      }
    >
      <div className={'flex justify-between items-center w-full'}>
        <div className={'flex items-center mobile:w-[50%] desktop:w-[30%]'}>
          <img
            src={item?.operation.company?.translations?.[lang].logo}
            className={'w-[25px] h-auto mr-[10px] mobile:w-[50px] mobile:mr-[20px] desktop:w-[70px]'}
            alt={`logo`}
          />
          <div className={'flex flex-col'}>
            <div className={'font-600  text-1.6 mobile:text-2 leading-[20px] mobile:leading-[28px] text-white'}>
              {item?.operation.company?.translations?.[lang].title}
            </div>
            <div
              className={
                'flex desktop:hidden whitespace-nowrap flex-row text-1 mobile:text-1.2 font-400 text-white opacity-60 leading-[12px] mobile:leading-[16px]'
              }
            >
              <div>{i18n.t('plural.lot', { count: item.quantity })}</div>
              <div className={'mx-[10px]'}>|</div>
              <div>{`${item?.quantity * Number(item.price_per_security)} $`}</div>
            </div>
          </div>
        </div>
        <div className={'grid desktop:grid-cols-3 mobile:w-[30%] mobile:justify-end desktop:w-[50%]'}>
          <div className={'hidden desktop:flex items-center justify-center text-2 leading-[28px]'}>
            <div>{i18n.t('plural.lot', { count: item.quantity })}</div>
          </div>
          <div className={'hidden desktop:flex items-center justify-center text-2 leading-[28px]'}>{`${
            item?.quantity * Number(item.price_per_security)
          } $`}</div>
        </div>
      </div>
    </div>
  );
};
