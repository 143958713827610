import React, { useState } from 'react';

import { Columns } from './table/columns';
import { Row } from './table/row';
import { DealSellScenario } from '../dealSellScenario/dealSellScenario';

import { IPortfolioDeals, useGetActiveP2PDealsQuery, useGetPorfolioDealsQuery } from '7-entities/user';

import { EmptyData, ISwitchTab, Loader, Pagination, Switch } from '8-shared/ui';
import { useLocales } from '8-shared/lib/i18n';
import { useWindowSize } from '8-shared/hooks';
import { P2PColumns } from './table/p2pColumns';
import { P2PRow } from './table/p2pRow';

const PAGE_SIZE = 10;

export const PortfolioDeals = () => {
  const [currentItem, setCurrentItem] = useState<IPortfolioDeals | null>(null);
  const { width } = useWindowSize();
  const [isOpenSellModal, setIsOpenSellModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const onSell = (item: IPortfolioDeals) => {
    setCurrentItem(item);
    setIsOpenSellModal(true);
  };
  const onClose = () => {
    setCurrentItem(null);
    setIsOpenSellModal(false);
  };
  const { t, i18n } = useLocales('portfolio');

  const tabs = [
    { value: undefined, title: t('table.filters.all') },
    { value: 'Pre-IPO', title: t('table.filters.venture') },
    { value: 'Property', title: t('table.filters.real_estate') },
    { value: 'Alternative', title: t('table.filters.alternative') },
    { value: 'P2P-Offers', title: t('table.filters.p2p_offers') },
  ];

  const [filter, setFilter] = useState<ISwitchTab>(tabs[0]);

  const onChangeHandler = (tab: ISwitchTab) => {
    setFilter(tab);
  };

  const { data, isFetching } = useGetPorfolioDealsQuery({ type: filter.value, page: currentPage, page_size: PAGE_SIZE });
  const p2pOffers = useGetActiveP2PDealsQuery({ type: filter.value, page: currentPage, page_size: PAGE_SIZE });
  const totalCount = Math.ceil((data?.count || 0) / PAGE_SIZE);

  return (
    <>
      <DealSellScenario item={currentItem} onClose={onClose} isOpen={isOpenSellModal} />
      {isFetching ? (
        <Loader />
      ) : (
        <div className={'flex flex-col gap-4'}>
          <Switch tabs={tabs} currentTab={filter} onChange={onChangeHandler} />
          {filter.value !== 'P2P-Offers' ? (
            !data?.results?.length ? (
              <EmptyData title={filter.value ? i18n.t('common.empty_table_deal') : i18n.t('common.empty_table')} />
            ) : (
              <>
                <Columns />
                {data?.results.map((i, index) => (
                  <Row key={index} item={i} onSell={onSell} />
                ))}
              </>
            )
          ) : !p2pOffers?.data?.results?.length ? (
            <EmptyData title={filter.value ? i18n.t('common.empty_table_deal') : i18n.t('common.empty_table')} />
          ) : (
            <>
              <P2PColumns />
              {p2pOffers?.data?.results.map((i, index) => (
                <P2PRow key={index} item={i} />
              ))}
            </>
          )}
          <Pagination
            disabled={isFetching}
            className={'mt-10 w-max mx-auto'}
            total={totalCount}
            value={currentPage}
            onChange={setCurrentPage}
            size={width > 640 ? 'lg' : 'sm'}
          />
        </div>
      )}
    </>
  );
};
