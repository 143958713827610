function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

function setCookie(name: string, value: string, days = 7) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

function removeCookie(name: string) {
  document.cookie = `${name}=; Max-Age=0; path=/`;
}

export const JWTToken = {
  getAccess() {
    return localStorage.getItem('access_token') || getCookie('access_token');
  },
  getRefresh() {
    return localStorage.getItem('refresh_token');
  },
  setTokens(access: string, refresh: string) {
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
    setCookie('access_token', access);
  },
  removeTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    removeCookie('access_token');
  },
};
