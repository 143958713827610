export const AUTH_TAG = 'AUTH_TAG';
export const COMPANIES_TAG = 'COMPANIES_TAG';
export const P2P_TAG = 'P2P_TAG';
export const USER = 'USER';
export const IPO = 'IPO_TAG';
export const WALLET = 'WALLET';
export const HISTORY = 'HISTORY';
export const DOCUMENTS = 'DOCUMENTS';
export const PORTFOLIO_DEALS = 'PORTFOLIO_DEALS';
export const P2P_OFFERS = 'P2P_OFFERS';
export const DIGITAL_SAFE = 'DIGITAL_SAFE';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const PROFILE_INFO = 'PROFILE_INFO';
